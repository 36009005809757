<template>
  <div class="app-main">
    <div class="xq_banner"><img src="@/assets/img/banner1.png"></div>
    <div class="app-container">
      <div class="position">
        <h2>功法视频</h2>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>功法视频</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="Gongfa">
        <el-row :gutter="24" >
          <el-col :xs="24" :sm="12" :md="12" :lg="12">
            <h5>第一式  两手托天理三焦：</h5>
            <video width="100%" height="auto" controls>
              <source src="http://www.shengyijingfang.com/video/lsttlsj.mp4" type="video/mp4">
              您的浏览器不支持Video标签。
            </video>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12">
            <h5>第二式  调理脾胃须单举：</h5>
            <video width="100%" height="auto" controls>
              <source src="http://www.shengyijingfang.com/video/tlpwxdj.mp4" type="video/mp4">
              您的浏览器不支持Video标签。
            </video>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12">
            <h5>第三式   五劳七伤往后瞧：</h5>
            <video width="100%" height="auto" controls>
              <source src="http://www.shengyijingfang.com/video/wlqswhq.mp4" type="video/mp4">
              您的浏览器不支持Video标签。
            </video>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12">
            <h5>第四式  左右开弓似射雕：</h5>
            <video width="100%" height="auto" controls>
              <source src="http://www.shengyijingfang.com/video/zykgssd.mp4" type="video/mp4">
              您的浏览器不支持Video标签。
            </video>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  methods: {
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.Gongfa{
  padding: 50px 0;
      h5{
        font-size: 18px;
        margin-bottom: 8px;
      }
      video{
        margin-bottom: 10px;
      }
}
</style>